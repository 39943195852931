import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { VWOrderPersonAttachmentModule } from '../../../appshared/orders/order-person-attachment/order-person-attachment.module';
import { OrderPersonAttachmentService } from '../../../appshared/orders/order-person-attachment/order-person-attachment.service';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';
import { PersonalAttachmentDialogComponent } from '../../applicationForm/step4-attachment/personal-attachment-dialog/personal-attachment-dialog.component';
import { OrderUserCommentService } from '../../../appshared/orders/order-user-comment/order-user-comment.service';
import { OrderUserCommentModule } from '../../../appshared/orders/order-user-comment/order-user-comment.module';

@Component({
  selector: 'app-download-visa',
  templateUrl: './download-visa.component.html',
  styleUrls: ['./download-visa.component.scss']
})
export class DownloadVisaComponent implements OnInit {
    constructor(public dialog: MatDialog, private route: ActivatedRoute, private servOrderUserComment: OrderUserCommentService, private servAttachment: OrderPersonAttachmentService, private loader: AppLoaderService, public translate: TranslateService)
    {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }

    }

    public SelectedLanguage: string = "en";
    public SelectedCurrID: number = 1;
    private sub: any;
    ono: number = 0;
    email: string = "";
    types: string = "";
    oid: number = 0;
    public apiURL: string = "";


    public dataSourceOrderUserComment = new MatTableDataSource<OrderUserCommentModule>();
    displayedColumnsOrderUserComment = [ 'details'];


    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.oid = +params['oid'];
            this.ono = +params['ono'];
            this.email = params['email'];
            this.types = params['types'];
        });
        this.apiURL = environment.apiUrl2 + 'Resources/Attachment/Order/';
        this.getAttachmentTable();
        this.getViewOrderUserComment();
    }

    public dataSourceAttachment = new MatTableDataSource<VWOrderPersonAttachmentModule>();
    displayedColumnsAttachment = ['num', 'firstName' ,'attachmentTypeNameEn',  'actions'];

    getAttachmentTable() {
       /* this.loader.open();*/
        this.servAttachment.getServiceVWOrder(this.oid, this.types)
            .subscribe(res => {
               /* this.loader.close();*/
                var data1 = res as VWOrderPersonAttachmentModule[];
                if (this.SelectedLanguage == "ar")
                {
                    for (var i = 0; i < data1.length; i++)
                    {
                        data1[i].attachmentTypeNameEn = data1[i].attachmentTypeNameAr;
                    }
                }
                this.dataSourceAttachment.data = data1;
            },
                err => {
                    /* this.loader.close();*/
                    console.log(err);
                });
    }

    goToLink(filePath) {
        window.open(environment.apiUrl2 + 'Resources/Attachment/Order/' + filePath, "_blank");
    }



    ////////////////////////////////////////////////



    public getViewOrderUserComment = () => {
        this.dataSourceOrderUserComment.data = null;
        this.servOrderUserComment.getServiceOrderCommentType(this.oid, this.types)
            .subscribe(res => {
                this.dataSourceOrderUserComment.data = res as OrderUserCommentModule[];
            })
    }
}
