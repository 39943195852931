import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { VWOrderModule } from '../../../appshared/orders/order/order.module';
import { OrderService } from '../../../appshared/orders/order/order.service';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';

@Component({
  selector: 'app-tracking-result',
  templateUrl: './tracking-result.component.html',
  styleUrls: ['./tracking-result.component.scss']
})
export class TrackingResultComponent implements OnInit {

    private sub: any;
    ono: number = 0;
    email: string = "";
    private datas: any;
    public isDisabledSuccess: boolean = true;

    public SelectedLanguage: string = "en";

    closedVisaTXT: string = "";
    countryName: string = "";
    stayDays: string = "";
    orderNos: string = "";
    fromDate = new Date();
    toDate = new Date();
    isDetails: boolean = false;
    isDownloadVisa: boolean = false;
    isReferBack: boolean = false;
    

    constructor(private router: Router, private route: ActivatedRoute, public service: OrderService, private toastr: ToastrService, public translate: TranslateService, private loader: AppLoaderService) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.ono = +params['ono'];
            this.email = params['email'];
        });

        this.buildItemForm();
    }


    buildItemForm() {

        this.loader.open();
        this.service.getServiceVWTrack(this.ono, this.email)
            .subscribe(res => {
                this.datas = res as VWOrderModule[];

                this.isDisabledSuccess = true;

                this.service.VWformData = this.datas;

                this.stayDays = this.service.VWformData.stayPeriod.toString();
                this.orderNos = this.service.VWformData.orderNo.toString();

                if  (this.service.VWformData.status == "Approved Visa") {
                    this.isDownloadVisa = true;
                }
                else if (this.service.VWformData.status == "Closed Visa" || this.service.VWformData.status == "Refund and Paid" || this.service.VWformData.status == "Refund" || this.service.VWformData.status == "Rejected")
                {
                    this.isDetails = true;
                    
                }
                else if (this.service.VWformData.status == "Closed Visa") {

                    if (this.SelectedLanguage == "en") {
                        this.closedVisaTXT = "Your visa status is closed and not available any more for download. Please contact us if you need any further assistance.";
                    }
                    else {
                        this.closedVisaTXT = "حالة التأشيرة الخاصة بك مغلقة وليست متاحة للتحميل. يرجى التواصل معنا للمساعدة.";
                    }

                }

                if (this.service.VWformData.status == "Refer Back") {

                    this.isReferBack = true;
                 
                }

            



                if (this.SelectedLanguage == "en") {
                    this.countryName = this.service.VWformData.visaCountryNameEn;
                }
                else {
                    this.countryName = this.service.VWformData.visaCountryNameAr;

                    this.service.VWformData.currencyNameEn = this.service.VWformData.currencyNameAr;
                    this.service.VWformData.visaTypeNameEn = this.service.VWformData.visaTypeNameAr;
                    this.service.VWformData.visaCountryNameEn = this.service.VWformData.visaCountryNameAr;
                    this.service.VWformData.countryNameEn = this.service.VWformData.countryNameAr;
                    this.service.VWformData.nationalityNameEn = this.service.VWformData.nationalityNameAr;
                }

                this.fromDate = this.service.VWformData.validFromDate;
                this.toDate = this.service.VWformData.validToDate;
                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
            

                    this.toastr.warning('Not Available', '');

                    this.router.navigate(['tracking']);

                });
    }

    GoDownloadVisaBT() {

        this.router.navigate(['download-visa', { oid: this.service.VWformData.orderID, ono: this.ono, email: this.email, types: 'Approved Visa' }]);
    }

    GoDetailsBT() {

        this.router.navigate(['download-visa', { oid: this.service.VWformData.orderID, ono: this.ono, email: this.email, types: this.service.VWformData.status }]);
    }

    GoReferBackBT() {

        this.router.navigate(['refer-back-attachment', { oid: this.service.VWformData.orderID, ono: this.ono, email: this.email }]);
    }

}
