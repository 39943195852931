<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'Tracking_Application' | translate }}</h2>
        </div>
    </div>
</div>


    <fieldset [hidden]="!isDisabledSuccess">
        <section class="contact-area ptb-70 bg-light">
            <div class="container">



                <div class="card recent-orders-box">
                    <div class="card-body">

                        <div class="blog-details-desc text-center">
                            <div class="article-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li>
                                            <i class='bx bx-globe'></i>
                                            <span>{{'Country' | translate }}</span>
                                            <a>{{ countryName }}</a>
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar text-success'></i>
                                            <span>{{'Valid_from' | translate }}</span>
                                            <a>{{fromDate | date  : 'dd/MM/yyyy'}}</a>
                                        </li>
                                        <li>
                                            <i class='bx bx-time-five'></i>
                                            <span>{{'Stay_days' | translate }}</span>
                                            <a>{{ stayDays }}</a>
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar text-danger'></i>
                                            <span>{{'Valid_to' | translate }}</span>
                                            <a>{{toDate | date  : 'dd/MM/yyyy'}}</a>
                                        </li>
                                        <li>
                                            <i class='bx bxs-file'></i>
                                            <span>{{'Order_No' | translate }}</span>
                                            <a>{{ orderNos }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>



                <div class="card recent-orders-box" style="padding:15px">
                    <div class="card-body">

                        <h3>{{'Order_Details' | translate}}</h3>

                        <form autocomplete="off" #userlogin="ngForm">
                            <div class="row">

                                <div class="col-xs-12 col-12 col-md-6 form-group">
                                    <label>{{'Order_Date' | translate }}</label>
                                    <input type="text" class="form-control" name="arrivalDate" value="{{service.VWformData.arrivalDate | date  : 'dd/MM/yyyy'}}" disabled>
                                </div>

                                <div class="col-xs-12 col-12 col-md-6 form-group">
                                    <label>{{'Status' | translate }}</label>
                                    <input type="text" class="form-control" name="status" #status="ngModel" [(ngModel)]="service.VWformData.status" disabled>
                                </div>

                                <div class="col-xs-12 col-12 col-md-6 form-group">
                                    <label>{{'Visa_Type' | translate }}</label>
                                    <input type="text" class="form-control" name="visaTypeNameEn" #visaTypeNameEn="ngModel" [(ngModel)]="service.VWformData.visaTypeNameEn" disabled>
                                </div>



                                <div class="col-xs-12 col-12 col-md-6 form-group">
                                    <label>{{'Nationality' | translate }}</label>
                                    <input type="text" class="form-control" name="nationalityNameEn" #nationalityNameEn="ngModel" [(ngModel)]="service.VWformData.nationalityNameEn" disabled>
                                </div>


                                <div class="col-xs-12 col-12 col-md-6 form-group">
                                    <label>{{'Email' | translate }}</label>
                                    <input type="text" class="form-control" name="email" #email="ngModel" [(ngModel)]="service.VWformData.email" disabled>
                                </div>


                                <div class="col-xs-12 col-12 col-md-6 form-group" *ngIf="service.VWformData.status == 'Refund'">
                                    <label>{{'Refund_Amount' | translate }}</label>
                                    <input type="text" class="form-control" name="refundAmount" #refundAmount="ngModel" [(ngModel)]="service.VWformData.refundAmount" disabled>
                                </div>


                                <div class="col-xs-12 col-12 col-md-6 form-group" *ngIf="service.VWformData.status == 'Refund'">
                                    <label>{{'Refund_Remark' | translate }}</label>
                                    <input type="text" class="form-control" name="refundRemark" #refundRemark="ngModel" [(ngModel)]="service.VWformData.refundRemark" disabled>
                                </div>


                            </div>
                        </form>

                        <div>
                            <p style="font-weight:bold; color:darkred;">{{ closedVisaTXT }}</p>
                            <button class="default-btnWhite" *ngIf="isDownloadVisa" style="margin: 20px" type="button" (click)="GoDownloadVisaBT()"><i class="bx bx-plus"></i> {{"Download_Visa" | translate }}</button>

                            <button class="default-btnWhite" *ngIf="isReferBack" type="button" (click)="GoReferBackBT()">{{'Refer_Back' | translate }}</button>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </fieldset>
